<template>
  <b-card header="Add Question">
    <poll-form
      :submit="addPoll"
      :poll="poll"
    />
  </b-card>
</template>
<script>
import PollForm from '@/common/components/Polls/PollForm.vue'
import handleAlerts from '@/common/compositions/common/handleAlerts'

export default {
  name: 'AddPoll',
  components: { PollForm },
  data() {
    return {
      poll: { answers: [{ body: '' }, { body: '' }, { body: '' }] },
    }
  },
  setup() {
    const { successfulOperationAlert } = handleAlerts()
    return { successfulOperationAlert }
  },
  methods: {
    addPoll() {
      return this.$activities.post(`/internalops/poll-session/${this.$route.params.liveId}/poll`, this.poll).then(() => {
        this.successfulOperationAlert('Question is added successfully')
        this.$router.push({ name: 'polls-list' })
      })
    },
  },
}
</script>
<style lang="scss">

</style>
